import React, {ForwardedRef, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {STATIC} from "../constants";
import {APIException, get_track, is_admin, refresh_track} from "../api";
import {DropdownButton, DropdownButtonImage, MIDTITLE_FONT_SIZE, PASTEL_PURPLE} from "../components/reusable/styled/StyledComponents";
import styled from "styled-components";
import {ExternalUtils, SortDirection, SortExternalTrackBy, TrackBase, TrackUtils} from "../types";
import {BodyContainer, ContentContainer} from "../components/reusable/styled/Container";
import {ExternalTrackSortHeaders} from "../components/SortHeader";
import {SortableExternalTrackCard} from "../components/cards/SortableTrackCard";
import {setWindowProperties} from "../util/format";
import {Helmet} from "react-helmet";
import toast from "react-hot-toast";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {TrackMenu} from "../components/reusable/functional/Menu";
import {Lost} from "./lost";

/**
 * TODO
 *  - artists at the top, album (for each external instead of artist in the card), duration
 *  - format name to scroll like playlist description (almost every title, description, etc should use this system)
 */

const TrackContentContainer = styled(ContentContainer)`
    grid-template-columns: auto 15px 1fr 15px auto 15px auto;
    grid-template-rows: auto 5px auto 25px auto 15px auto;
`;

const Cover = styled.img`
    grid-column: 1;
    grid-row: 1 / 4;
    width: 125px;
    height: 125px;
    object-fit: contain;
    cursor: pointer;
    background-color: #00000040;
    box-shadow: 0 0 10px 5px #00000040;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        transform: scale(1.02);
    }
`;

const Name = styled.p`
    width: 100%;
    height: 80px;
    line-height: 80px;
    color: ${PASTEL_PURPLE};
    font-size: ${MIDTITLE_FONT_SIZE};
    font-weight: bold;
    grid-column: 3;
    grid-row: 1;
    
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const PositionedDropdownButton = styled(DropdownButton)`
    grid-column: 7;
    grid-row: 1 / 4;
`;

const TrackExternalsResults = styled.div`
    width: 100%;
    grid-column: 1 / span 9999;
    grid-row: 7;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
`;

export function Track() {
    const params = useParams();
    const trackId = Number(params.id);

    const [track, setTrack] = useState<TrackBase | null>(TrackUtils.empty());

    const [sortBy, setSortBy] = useState<SortExternalTrackBy>("releaseDate");
    const [sortDirection, setSortDirection] = useState<SortDirection>("descending");
    const [cards, setCards] = useState<React.JSX.Element[]>([]);
    const [state, setState] = useState(true); // whenever track is modified
    const [isAdmin, setIsAdmin] = useState(false);
    const refreshState = () => setState(!state);

    useEffect(() => {
        (async() => {
            try {
                setIsAdmin(await is_admin());
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async() => {
            try {
                const track = await get_track(trackId);
                setTrack(track);
                setWindowProperties(track.name, "Listen to " + track.name + " on Amogustream.",
                    TrackUtils.getCover(track));
            } catch (e) {
                if (e instanceof APIException) {
                    toast.error(e.message);
                    setTrack(null);
                }
            }
        })();
    }, [state, trackId]);

    useEffect(() => {
        (async() => {
            try {
                await refresh_track(trackId);
                refreshState();
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, [trackId]);

    useEffect(() => {
        if (track == null) return;
        let actualCards = [];
        for (const external of ExternalUtils.sortForTrack(track.externals, sortBy, sortDirection)) {
            actualCards.push(
                <SortableExternalTrackCard
                    track={track} // TODO this should be phased out when name is in external
                    external={external}
                    number={actualCards.length + 1}
                    refreshState={refreshState}
                    sortTrackBy={sortBy}
                    sortDirection={sortDirection}
                    key={external.external_id + external.platform}
                />
            );
        }
        setCards(actualCards);
    }, [track, sortBy, sortDirection]);

    // Other Functions
    const onSort = (newSortBy: SortExternalTrackBy) => {
        if (newSortBy != sortBy) {
            setSortDirection("ascending");
            setSortBy(newSortBy);
        } else {
            setSortDirection(sortDirection == "ascending" ? "descending" : "ascending");
        }
    };

    if (track == null) return <Lost/>;

    return <BodyContainer>
        <Helmet>
            <meta property="og:title" content={track.name}/>
            <meta property="og:description" content={"Listen to " + track.name + " on Amogustream."}/>
            <meta property="og:image" content={TrackUtils.getCover(track)}/>
        </Helmet>

        <TrackContentContainer>
            <Cover src={TrackUtils.getCover(track)}/>
            <Name>{track.name}</Name>
            <TrackMenu
                items={[[]]}
                menuType={DropdownMenu}
                    component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                        <PositionedDropdownButton {...props} ref={forwardedRef}>
                            <DropdownButtonImage src={STATIC + "dropdown.png"}/>
                        </PositionedDropdownButton>
                    )}
                track={track}
                isAdmin={isAdmin}
                refreshState={refreshState}
            />

            <ExternalTrackSortHeaders gridRow={5} onSort={onSort} stateSortBy={sortBy} stateSortDirection={sortDirection}/>

            <TrackExternalsResults>{cards}</TrackExternalsResults>
        </TrackContentContainer>
    </BodyContainer>
}