import styled from "styled-components";
import {BannerButton, BannerButtonImage, BannerButtonText} from "../reusable/styled/BannerButton";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getNewPlaylistOverlayVisibility, setImportPersonalPlaylistOverlayVisibility, setImportPlaylistHastebinOverlayVisibility, setImportPlaylistURLOverlayVisibility, setNewPlaylistOverlayVisibility, showCreatePlaylistOverlay} from "../../redux-store/overlaySlice";
import {Overlay, OverlayContentContainer} from "../reusable/styled/Overlay";
import {onOverlayClick} from "../../util/functions";
import {OverlayTitle} from "../reusable/styled/Title";
import {STATIC} from "../../constants";
import React from "react";

const EmptyPlaylistButton = styled(BannerButton)`
    grid-row: 3;
`;

const ImportPersonalPlaylistButton = styled(BannerButton)`
    grid-row: 4;
`;

const ImportUrlPlaylistButton = styled(BannerButton)`
    grid-row: 5;
`;

const ImportHastebinPlaylistButton = styled(BannerButton)`
    grid-row: 6;
`;

export function NewPlaylistOverlay() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const visible = useSelector(getNewPlaylistOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setNewPlaylistOverlayVisibility(newVisibility));

    const onCreatePlaylist = () => {
        dispatch(showCreatePlaylistOverlay((playlist_id: number) => navigate("/playlist/" + playlist_id)));
        setVisibility(false);
    };

    const onPersonalImport = () => {
        dispatch(setImportPersonalPlaylistOverlayVisibility(true));
        setVisibility(false);
    };

    const onUrlImport = () => {
        dispatch(setImportPlaylistURLOverlayVisibility(true));
        setVisibility(false);
    };

    const onHastebinImport = () => {
        dispatch(setImportPlaylistHastebinOverlayVisibility(true));
        setVisibility(false);
    };

    const overlayId = "newPlaylistOverlay";
    return <Overlay id={overlayId + "Bg"} visible={visible} setVisibility={setVisibility} onClick={(e) => onOverlayClick(e, overlayId, setVisibility)}>
        <OverlayContentContainer id={overlayId}>
            <OverlayTitle>New Playlist</OverlayTitle>
            <EmptyPlaylistButton onClick={onCreatePlaylist}>
                <BannerButtonImage src={STATIC + "empty.png"}/>
                <BannerButtonText>Empty Playlist</BannerButtonText>
            </EmptyPlaylistButton>

            <ImportPersonalPlaylistButton onClick={onPersonalImport}>
                <BannerButtonImage src={STATIC + "import.png"}/>
                <BannerButtonText>Import Personal Playlist</BannerButtonText>
            </ImportPersonalPlaylistButton>

            <ImportUrlPlaylistButton onClick={onUrlImport}>
                <BannerButtonImage src={STATIC + "link.png"}/>
                <BannerButtonText>Import Playlist from URL</BannerButtonText>
            </ImportUrlPlaylistButton>

            <ImportHastebinPlaylistButton onClick={onHastebinImport}>
                <BannerButtonImage src={STATIC + "paste.png"}/>
                <BannerButtonText>Import Playlist from Hastebin</BannerButtonText>
            </ImportHastebinPlaylistButton>
        </OverlayContentContainer>
    </Overlay>
}