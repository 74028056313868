import styled from "styled-components";
import {TrackUtils} from "../../types";
import React, {useEffect, useState} from "react";
import {APIException, in_playlists} from "../../api";
import {AddTrackPlaylistCard} from "../cards/PlaylistCard";
import {onOverlayClick} from "../../util/functions";
import {Overlay, OverlayContentContainer} from "../reusable/styled/Overlay";
import {OverlayTitle} from "../reusable/styled/Title";
import {BannerButtonImage, BannerButtonText, WideBannerButton} from "../reusable/styled/BannerButton";
import {STATIC} from "../../constants";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {getAddTrackOverlayRefresh, getAddTrackOverlayTrack, getAddTrackOverlayVisibility, setAddTrackOverlayVisibility, showCreatePlaylistOverlay} from "../../redux-store/overlaySlice";

const AddTrackPlaylistOverlayContentContainer = styled(OverlayContentContainer)`
    width: 1200px;
`;

const NewPlaylistButton = styled(WideBannerButton)`
    grid-row: 3;
`;

const AddTrackPlaylistResults = styled.div`
    width: 100%;
    grid-row: 5;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
`;

export function AddTrackPlaylistOverlay() {
    const dispatch = useDispatch();
    const [playlists, setPlaylists] = useState<React.JSX.Element[]>([]);
    const [state, setState] = useState(true); // whenever playlist is modified
    const track = useSelector(getAddTrackOverlayTrack);
    const visible = useSelector(getAddTrackOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setAddTrackOverlayVisibility(newVisibility));
    const refreshState = useSelector(getAddTrackOverlayRefresh);
    const completeRefreshState = () => {
        setState(!state); // recalculate intersections
        refreshState();
    };

    useEffect(() => {
        if (!visible || TrackUtils.isEmpty(track)) return;
        (async () => {
            try {
                const [externalId, platform] = TrackUtils.getExternalIdAndPlatform(track);
                const results = await in_playlists(null, externalId, platform);

                let actualPlaylists = [];
                for (const playlist of results) {
                    actualPlaylists.push(
                        <AddTrackPlaylistCard playlist={playlist} track={track} setVisibility={setVisibility}
                                              refreshState={completeRefreshState}
                                              key={playlist.playlist_id}/>
                    )
                }
                setPlaylists(actualPlaylists);
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, [visible, state]);

    const overlayId = "addTrackPlaylistOverlay";
    return <Overlay id={overlayId + "Bg"} visible={visible} setVisibility={setVisibility}
                    onClick={(e) => onOverlayClick(e, overlayId, setVisibility)}>
        <AddTrackPlaylistOverlayContentContainer id={overlayId}>
            <OverlayTitle>Add to Playlist</OverlayTitle>
            <NewPlaylistButton onClick={() => dispatch(showCreatePlaylistOverlay(completeRefreshState))}>
                <BannerButtonImage src={STATIC + "empty.png"}/>
                <BannerButtonText>Create New Playlist</BannerButtonText>
            </NewPlaylistButton>
            <AddTrackPlaylistResults>{playlists}</AddTrackPlaylistResults>
        </AddTrackPlaylistOverlayContentContainer>
    </Overlay>
}