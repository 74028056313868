import styled from "styled-components";
import {OverlayProps} from "../../types";
import React from "react";
import {onOverlayClick} from "../../util/functions";
import {Overlay, OverlayContentContainer} from "../reusable/styled/Overlay";
import {OverlayTitle} from "../reusable/styled/Title";
import {ExternalTrackCard} from "../cards/ExternalCard";
import {pick} from "lodash";
import {getTrackExternalsOverlayTrack, getTrackExternalsOverlayVisibility, setTrackExternalsOverlayVisibility} from "../../redux-store/overlaySlice";
import {useDispatch, useSelector} from "react-redux";

const BaseExternalsOverlayContentContainer = styled(OverlayContentContainer)`
    width: 1200px;
`;

const Results = styled.div`
    width: 100%;
    grid-row: 3;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
`;

interface BaseExternalsOverlayProps extends OverlayProps {
    externalResults: React.JSX.Element[];
}

function BaseExternalsOverlay(props: BaseExternalsOverlayProps) {
    const overlayId = "externalsOverlay";
    return <Overlay id={overlayId + "Bg"} {...pick(props, ["visible", "setVisibility"])}
                    onClick={(e) => onOverlayClick(e, overlayId, props.setVisibility)}>
        <BaseExternalsOverlayContentContainer id={overlayId}>
            <OverlayTitle>External Links</OverlayTitle>
            <Results>
                {props.externalResults}
            </Results>
        </BaseExternalsOverlayContentContainer>
    </Overlay>
}

export function TrackExternalsOverlay() {
    const dispatch = useDispatch();
    const track = useSelector(getTrackExternalsOverlayTrack);
    const visible = useSelector(getTrackExternalsOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setTrackExternalsOverlayVisibility(newVisibility));

    let newResults: React.JSX.Element[] = [];
    for (const external of track.externals) {
        newResults.push(<ExternalTrackCard external={external} key={external.external_id + external.platform}/>)
    }

    return <BaseExternalsOverlay externalResults={newResults} visible={visible} setVisibility={setVisibility}/>
}