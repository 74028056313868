import React, {ForwardedRef, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {APIException, follow_artist, get_artist, is_admin, refresh_artist, unfollow_artist} from "../api";
import {DropdownButton, DropdownButtonImage, MIDTITLE_FONT_SIZE, PASTEL_PURPLE} from "../components/reusable/styled/StyledComponents";
import {STATIC} from "../constants";
import styled from "styled-components";
import {ArtistBase, ArtistUtils, SortDirection, SortTrackBy, TrackBase, TrackUtils} from "../types";
import {ArtistTrackCard} from "../components/cards/SortableTrackCard";
import {getExternalLink, setWindowProperties} from "../util/format";
import {useSelector} from "react-redux";
import {getStorageKey} from "../redux-store/userSlice";
import {BodyContainer, ContentContainer} from "../components/reusable/styled/Container";
import {BannerButton, BannerButtonImage, BannerButtonText} from "../components/reusable/styled/BannerButton";
import {TrackSortHeaders} from "../components/SortHeader";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {Helmet} from "react-helmet";
import toast from "react-hot-toast";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {ArtistMenu, ExternalMenu} from "../components/reusable/functional/Menu";
import * as ContextMenu from "@radix-ui/react-context-menu";
import {Lost} from "./lost";

// TODO check what happens when artist name is too long (can maybe animate horizontal movement with some hover stuff)
const ArtistContentContainer = styled(ContentContainer)`
    grid-template-columns: auto 15px 1fr 15px auto 15px;
    grid-template-rows: auto 0 auto 25px auto 15px; // 2nd row can be adjusted if desired
`;

const Image = styled.img`
    grid-column: 1;
    grid-row: 1 / 4;
    width: 125px;
    height: 125px;
    cursor: pointer;
    box-shadow: 0 0 10px 5px #00000040;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        transform: scale(1.02);
    }
`;

const Name = styled.p`
    justify-self: left;
    height: 85px;
    line-height: 85px;
    font-size: ${MIDTITLE_FONT_SIZE};
    color: ${PASTEL_PURPLE};
    font-weight: bold;
    grid-column: 3;
    grid-row: 1;
    
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ExternalsContainer = styled.div`
    padding: 0 15px 0 15px;
    width: 100%;
    height: 40px;
    grid-column: 3 / 8;
    grid-row: 3;
    
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(9999, auto) 1fr;
`;

const Filler = styled.div`
    width: 10px;
`;

const External = styled(Link)`
    width: 30px;
    height: 30px;
    display: grid;
`;

const ExternalImage = styled.img`
    width: 30px;
    height: 30px;
    transition: all 0.1s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

const FollowButton = styled(BannerButton)`
    width: min-content;
    grid-column: 5;
    grid-row: 1 / 4;
`;

const PositionedDropdownButton = styled(DropdownButton)`
    grid-column: 7;
    grid-row: 1 / 4;
`;

const ArtistTrackResults = styled.div`
    width: 100%;
    grid-column: 1 / span 9999;
    grid-row: 8;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
`;

export function Artist() {
    const params = useParams();
    const artistId = Number(params.id);

    const loggedIn = !!useSelector(getStorageKey);
    const [artist, setArtist] = useState<ArtistBase | null>(ArtistUtils.empty());
    const [tracks, setTracks] = useState<TrackBase[]>([]);
    const [following, setFollowing] = useState(false);
    const [sortBy, setSortBy] = useState<SortTrackBy>("releaseDate");
    const [sortDirection, setSortDirection] = useState<SortDirection>("descending");

    const [cards, setCards] = useState<React.JSX.Element[]>([]);
    const [state, setState] = useState(true); // whenever artist is modified (follow, refresh, merge track)
    const refreshState = () => setState(!state);

    const [isAdmin, setIsAdmin] = useState(false);

    let externals: React.JSX.Element[] = [];
    if (artist != null) {
        for (const external of artist.externals) {
            const url = getExternalLink(external, "artistExternal");
            externals.push(
                <ExternalMenu
                    menuType={ContextMenu}
                    component={React.forwardRef((nestedProps, forwardedRef: ForwardedRef<any>) =>
                        <External to={url} target="_blank" key={externals.length} {...nestedProps} ref={forwardedRef}>
                            <ExternalImage src={STATIC + external.platform + ".png"}/>
                        </External>
                    )}
                    external={external}
                    isAdmin={isAdmin}
                    search={artist.name}
                    type="artistExternal"
                    refreshState={() => {}} // TODO
                />,
                <Filler key={external.platform + "filler"}/>
            );
        }
        externals.pop();
    }

    useEffect(() => {
        (async() => {
            try {
                setIsAdmin(await is_admin());
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async() => {
            try {
                const results = await get_artist(artistId);
                const artist = results.artist;
                setArtist(artist);
                setTracks(results.tracks);
                setFollowing(results.is_following);
                setWindowProperties(artist.name,"Listen to " + artist.name + "'s discography on Amogustream.",
                    ArtistUtils.getImage(artist));
            } catch (e) {
                if (e instanceof APIException) {
                    toast.error(e.message);
                    setArtist(null);
                }
            }
        })();
    }, [state, artistId]);

    useEffect(() => {
        (async() => {
            try {
                await refresh_artist(artistId);
                refreshState();
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, [artistId]);

    useEffect(() => {
        if (artist == null) return;
        let actualCards = [];
        for (const track of TrackUtils.sort(tracks, sortBy, sortDirection)) {
            actualCards.push(
                <ArtistTrackCard
                    track={track}
                    artist={artist}
                    number={actualCards.length + 1}
                    isAdmin={isAdmin}
                    refreshState={refreshState}
                    sortTrackBy={sortBy}
                    sortDirection={sortDirection}
                    key={track.track_id}
                />
            );
        }
        setCards(actualCards);
    }, [tracks, sortBy, sortDirection]);

    // Other Functions
    const onToggleFollow = async() => {
        try {
            if (following) {
                await unfollow_artist(artistId);
            } else {
                await follow_artist(artistId);
            }
            refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    const onSort = (newSortBy: SortTrackBy) => {
        if (newSortBy != sortBy) {
            setSortDirection("ascending");
            setSortBy(newSortBy);
        } else {
            setSortDirection(sortDirection == "ascending" ? "descending" : "ascending");
        }
    };

    if (artist == null) return <Lost/>;

    // TODO tabs for tracks, albums
    return <BodyContainer>
        <Helmet>
            <meta property="og:title" content={artist.name}/>
            <meta property="og:description" content={"Listen to " + artist.name + "'s discography on Amogustream."}/>
            <meta property="og:image" content={ArtistUtils.getImage(artist)}/>
        </Helmet>

        <ArtistContentContainer>
            <Image src={ArtistUtils.getImage(artist)}/> {/*TODO clicking brings up image*/}
            <Name>{artist.name}</Name>
            <ExternalsContainer>{externals}</ExternalsContainer>
            {loggedIn &&
                <FollowButton onClick={onToggleFollow}>
                    <BannerButtonImage src={STATIC + (following ? "unfollow.png" : "follow.png")}/>
                    <BannerButtonText>{following ? "Unfollow" : "Follow"}</BannerButtonText>
                </FollowButton>
            }
            <ArtistMenu
                menuType={DropdownMenu}
                component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                    <PositionedDropdownButton {...props} ref={forwardedRef}>
                        <DropdownButtonImage src={STATIC + "dropdown.png"}/>
                    </PositionedDropdownButton>
                )}
                artist={artist}
                isAdmin={isAdmin}
                refreshState={refreshState}
            />

            <TrackSortHeaders gridRow={5} onSort={onSort} stateSortBy={sortBy} stateSortDirection={sortDirection}/>

            <DragDropContext onDragEnd={() => {}}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <ArtistTrackResults ref={provided.innerRef} {...provided.droppableProps}>
                            {cards}
                            {provided.placeholder}
                        </ArtistTrackResults>
                    )}
                </Droppable>
            </DragDropContext>
        </ArtistContentContainer>
    </BodyContainer>
}