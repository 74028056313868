import React, {ForwardedRef, useContext, useEffect, useState} from "react";
import {STATIC} from "../../constants";
import {PlaybackPanel} from "./PlaybackPanel";
import {ContinuousPlaybackSource, ContinuousPlaybackSourceUtils, Platform, RangeProps, TrackUtils} from "../../types";
import {globalContext} from "../../index";
import {useDispatch, useSelector} from "react-redux";
import {getContinuousPlaybackSource, getExternalId, getPlatform, getTrack, getVolume, setTrack} from "../../redux-store/playbackSlice";
import {FOREGROUND, PASTEL_NAVY, PASTEL_PURPLE, SELECTION, SLIDER_STYLE, SMALL_FONT_SIZE, Tooltip, WHITE_TO_PLATFORM} from "../reusable/styled/StyledComponents";
import styled, {css} from "styled-components";
import {followCursor} from "tippy.js";
import {toInteger} from "lodash";
import {RangeInput} from "../reusable/styled/Input";
import {Link} from "react-router-dom";
import {PlaybackExternalSources} from "../reusable/functional/PlaybackExternalSource";
import {ArtistMenu, RedirectMenu, TrackMenu} from "../reusable/functional/Menu";
import * as ContextMenu from "@radix-ui/react-context-menu";
import {APIException, get_track, is_admin} from "../../api";
import toast from "react-hot-toast";
import {getExternalLink} from "../../util/format";

const MediaControllerContainer = styled.div`
    background-color: ${SELECTION};

    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;
    padding: 15px 15px 15px 15px;
    z-index: -1;

    overflow-x: auto;
    overflow-y: visible;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 15px minmax(425px, 1150px) 15px 1fr;
`;

const Container = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
`;

const LeftContainer = styled(Container)`
    grid-column: 1;
    justify-items: left;
    grid-template-columns: auto 10px auto 1fr;
`;

const RightContainer = styled(Container)`
    grid-column: 5;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto 5px auto;
`;

const Cover = styled.img`
    width: 40px;
    height: 40px;
    object-fit: contain;
    
    grid-column: 1;
    grid-row: 1 / 3;
    background-color: #00000040;
    box-shadow: 0 0 10px 5px #00000040;
`;

const LeftTopContainer = styled.div`
    max-width: 100%;
    grid-column: 3;
    grid-row: 1;
    overflow-x: auto;
    overflow-y: hidden;
    align-self: end;

    display: grid;
    grid-template-columns: 15px 5px 1fr;
`;

interface ExternalProps {
    platform: Platform | null;
}

const External = styled(Link)`
    width: 15px;
    height: 15px;
    grid-column: 1;
    display: grid;
`;

const ExternalImage = styled.img<ExternalProps>`
    width: 15px;
    height: 15px;
    transition: transform 0.1s ease-in-out;
    
    &:hover {
        transform: scale(1.2);
        ${props => props.platform != null && css`
            filter: ${WHITE_TO_PLATFORM(props.platform)}
        `}
    }
`;

const Name = styled(Link)`
    max-width: 100%;
    color: ${FOREGROUND};
    font-size: ${SMALL_FONT_SIZE};
    font-weight: bold;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    grid-column: 3;
    transition: all 0.1s ease-in-out;

    &:hover {
        color: ${PASTEL_PURPLE};
        height: auto;
    }
`;

// TODO remove side scrolling in favour of something else idk what yet
const Artists = styled.div`
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    
    grid-column: 3;
    grid-row: 2;
    
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(9999, auto) 1fr;
`;

const ArtistSeparator = styled.p`
    color: ${FOREGROUND};
    font-size: ${SMALL_FONT_SIZE};
    line-height: 15px;
    white-space: pre;
`;

const Artist = styled(Link)`
    color: ${FOREGROUND};
    font-size: ${SMALL_FONT_SIZE};
    line-height: 15px;
    white-space: pre;
    transition: all 0.1s ease-in-out;

    &:hover {
        color: ${PASTEL_PURPLE};
    }
`;

const VolumeButton = styled.img`
    width: 24px;
    height: 24px;
    grid-column: 2;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

interface VolumeProps extends RangeProps {
    volume: number;
}

const VolumeSlider = styled(RangeInput)<VolumeProps>`
    width: 100%;
    min-width: 50px;
    max-width: 150px;
    grid-column: 4;
    
    &::-webkit-slider-runnable-track {
        background: ${props => SLIDER_STYLE(props.volume * 100)};
    }
`;

const PlaybackSourceText = styled(Link)`
    color: ${FOREGROUND};
    font-size: 14px;
    transition: all 0.1s ease-in-out;

    &:hover {
        color: ${PASTEL_PURPLE};
    }
`;

interface PlaybackSourceProps {
    continuousPlaybackSource: ContinuousPlaybackSource;
}

function PlaybackSource(props: PlaybackSourceProps) {
    const [type, tooltipText] = ContinuousPlaybackSourceUtils.getSourceName(props.continuousPlaybackSource);
    return <PlaybackSourceText to={ContinuousPlaybackSourceUtils.getSourceUrl(props.continuousPlaybackSource)}>
        {"Currently playing from " + type + " · " + tooltipText}
    </PlaybackSourceText>
}

export function MediaController() {
    const {macroController} = useContext(globalContext);
    const dispatch = useDispatch();
    const track = useSelector(getTrack);
    const volume = useSelector(getVolume);
    const continuousPlaybackSource = useSelector(getContinuousPlaybackSource);
    const externalId = useSelector(getExternalId);
    const platform = useSelector(getPlatform);
    const [volumeHover, setVolumeHover] = useState(false);
    const [x, setX] = useState(0);

    const [, tooltipText] = ContinuousPlaybackSourceUtils.getSourceName(continuousPlaybackSource);
    const [isAdmin, setIsAdmin] = useState(false);

    const [state, setState] = useState(true); // TODO should make this a global state so any track edit etc will be reflected here
    const refreshState = () => setState(!state);

    useEffect(() => {
        (async() => {
            try {
                setIsAdmin(await is_admin());
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, []);

    useEffect(() => {
        if (track.track_id == null) return;
        (async() => {
            try {
                const updatedTrack = await get_track(track.track_id!);
                dispatch(setTrack(updatedTrack));
            } catch (e) {
                if (e instanceof APIException) {
                    toast.error(e.message);
                }
            }
        })();
    }, [state]);

    const externalUrl = track.track_id == null ? "" : getExternalLink(track.externals.filter((e) =>
        e.external_id == externalId && e.platform == platform
    )[0], "trackExternal");

    const volumeImage = STATIC + (volume == 0 ? "volume_mute.png" : (volume < 0.5 ? "volume_low.png" : "volume_high.png"));

    const onSetVolume = async(e: React.ChangeEvent<HTMLInputElement>) => {
        await macroController.setVolume(Number(e.target.value) / 1000);
    };

    const onToggleMute = async() => {
        await macroController.toggleMute();
    };

    const onMouseMove = (e: React.MouseEvent<HTMLInputElement>) => {
        const volumeSlider = e.target as HTMLInputElement;
        // https://stackoverflow.com/questions/30473169/how-to-get-the-value-of-the-hovered-time-on-input-range#comment64635650_32579181
        const newX = (e.clientX - volumeSlider.getBoundingClientRect().left - 6) / (volumeSlider.clientWidth - 12);
        setX(Math.max(Math.min(newX, 1), 0));
    };

    let artists: React.JSX.Element[] = [];
    for (const artist of track.artists) {
        const key = artist.artist_id;
        artists.push(
            <ArtistMenu
                menuType={ContextMenu}
                component={React.forwardRef((nestedProps, forwardedRef: ForwardedRef<any>) =>
                    <Artist to={"/artist/" + artist.artist_id} key={key} {...nestedProps} ref={forwardedRef}>
                        {artist.name}
                    </Artist>
                )}
                artist={artist}
                isAdmin={isAdmin}
                refreshState={refreshState}
            />,
            <ArtistSeparator key={key + "separator"}>
                {", "}
            </ArtistSeparator>
        );
    }
    artists.pop();

    return <Tooltip backgroundColour={SELECTION} arrowColour={SELECTION} interactive={true} disabled={tooltipText == ""}
                    hideOnClick={false} content={<PlaybackSource continuousPlaybackSource={continuousPlaybackSource}/>}>
        <MediaControllerContainer>
            <LeftContainer>
                {!TrackUtils.isEmpty(track) &&
                    <Tooltip backgroundColour={`linear-gradient(to top, ${PASTEL_NAVY} 0%, ${SELECTION} 100%)`}
                             appendTo={document.body} interactive={true}
                             content={<PlaybackExternalSources track={track}
                                                               continuousPlaybackSource={continuousPlaybackSource}/>}>
                        <Cover src={TrackUtils.getCover(track, externalId, platform)}/>
                    </Tooltip>
                }
                <LeftTopContainer>
                    {!TrackUtils.isEmpty(track) &&
                        <RedirectMenu
                            menuType={ContextMenu}
                            component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                                <External to={externalUrl} target="_blank" {...props} ref={forwardedRef}>
                                    <ExternalImage src={STATIC + platform + ".png"} platform={platform}/>
                                </External>
                            )}
                            url={externalUrl}
                        />
                    }
                    <TrackMenu
                        items={[[]]}
                        menuType={ContextMenu}
                        component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                            <Name as={Link} to={"/track/" + track.track_id} {...props} ref={forwardedRef}>{track.name}</Name>
                        )}
                        track={track}
                        isAdmin={isAdmin}
                        refreshState={refreshState}
                    />
                </LeftTopContainer>
                <Artists>{artists}</Artists>
            </LeftContainer>

            <PlaybackPanel/>

            <RightContainer>
                <VolumeButton onClick={onToggleMute} src={volumeImage}/>
                <Tooltip content={toInteger(x * 100) + "%"} visible={volumeHover}
                         followCursor="horizontal" plugins={[followCursor]}>
                    <VolumeSlider thumbVisible={volumeHover} volume={volume} type="range" min="0" max="1000"
                                  value={volume * 1000} onChange={onSetVolume} onMouseMove={onMouseMove}
                                  onMouseEnter={() => setVolumeHover(true)}
                                  onMouseLeave={() => setVolumeHover(false)}/>
                </Tooltip>
            </RightContainer>
        </MediaControllerContainer>
    </Tooltip>
}