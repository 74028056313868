import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {APIException, playlist_intersections} from "../../api";
import {MergePlaylistCard} from "../cards/PlaylistCard";
import {onOverlayClick} from "../../util/functions";
import {Overlay, OverlayContentContainer} from "../reusable/styled/Overlay";
import {OverlayTitle} from "../reusable/styled/Title";
import toast from "react-hot-toast";
import {getMergePlaylistDestId, getMergePlaylistOverlayRefresh, getMergePlaylistOverlayVisibility, setMergePlaylistOverlayVisibility} from "../../redux-store/overlaySlice";
import {useDispatch, useSelector} from "react-redux";

const MergePlaylistOverlayContentContainer = styled(OverlayContentContainer)`
    width: 1200px;
`;

const MergePlaylistResults = styled.div`
    width: 100%;
    grid-row: 3;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
`;

export function MergePlaylistOverlay() {
    const dispatch = useDispatch();
    const [playlists, setPlaylists] = useState<React.JSX.Element[]>([]);
    const [state, setState] = useState(true); // whenever playlist is modified
    const destPlaylistId = useSelector(getMergePlaylistDestId);
    const refreshState = useSelector(getMergePlaylistOverlayRefresh);
    const completeRefreshState = () => {
        setState(!state); // recalculate intersections
        refreshState();
    };
    const visible = useSelector(getMergePlaylistOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setMergePlaylistOverlayVisibility(newVisibility));

    useEffect(() => {
        if (!visible) return;
        (async () => {
            try {
                const results = await playlist_intersections(destPlaylistId!);

                let actualPlaylists: React.JSX.Element[] = [];
                for (const srcPlaylist of results) {
                    actualPlaylists.push(
                        <MergePlaylistCard
                            srcPlaylist={srcPlaylist}
                            destPlaylistId={destPlaylistId!}
                            setVisibility={setVisibility}
                            refreshState={completeRefreshState}
                            key={srcPlaylist.playlist_id}
                        />
                    )
                }
                setPlaylists(actualPlaylists);
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, [visible, state]);

    const overlayId = "mergePlaylistOverlay";
    return <Overlay id={overlayId + "Bg"} visible={visible} setVisibility={setVisibility} onClick={(e) => onOverlayClick(e, overlayId, setVisibility)}>
        <MergePlaylistOverlayContentContainer id={overlayId}>
            <OverlayTitle>Import tracks from another playlist</OverlayTitle>
            <MergePlaylistResults>
                {playlists}
            </MergePlaylistResults>
        </MergePlaylistOverlayContentContainer>
    </Overlay>
}